.header {
  position: relative;
  background-color: #151515;
  padding: 25px;
}

.header h2,
.header p {
  color: white;
}

.header h2 {
  margin-bottom: 5px;
}
