@import url('https://unpkg.com/@patternfly/patternfly@4.222.0/patternfly-addons.css');

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  /* Retina-specific stuff here */
  html {
    zoom: 0.85;
  }
}

html,
body,
#root {
  height: 100%;
}

.clearfix::after {
  content: '';
  clear: both;
  display: table;
}

.pf-c-tabs.customer-dashboard-tabs {
  background-color: white;
  --pf-c-tabs__link--PaddingTop: 22px;
  --pf-c-tabs__link--PaddingBottom: 22px;
  --pf-c-tabs__link--disabled--BackgroundColor: white;
}
.pf-c-tabs__item-text.inactive {
  opacity: 0.5;
}

.pf-c-content {
  --pf-c-content--small--Color: red; /* changes all <small> color to red */
  --pf-c-content--blockquote--BorderLeftColor: purple; /* changes all <blockquote> left border color to purple */
  --pf-c-content--hr--BackgroundColor: lemonchiffon; /* changes a <hr> color to lemonchiffon */
}

/* Custom window modal */
.pf-c-modal-box.custom-modal {
  --pf-c-modal-box__header--PaddingTop: 0;
  --pf-c-modal-box__header--PaddingRight: 0;
  --pf-c-modal-box__header--PaddingLeft: 0;
  --pf-c-modal-box__header--last-child--PaddingBottom: 0;
  --pf-c-modal-box__header--body--PaddingTop: 0;

  --pf-c-modal-box--m-lg--lg--MaxWidth: 75rem;
}
.pf-c-modal-box.custom-modal > .pf-c-button {
  z-index: 1;
  color: white;
  font-size: 20px;
  top: 0;
  right: 0;
  padding: 30px 32px;
  outline: none;
}
.pf-c-modal-box.custom-modal > .pf-c-button + * {
  margin-right: 0;
}
.pf-c-modal-box.custom-modal .pf-c-modal-box__header + .pf-c-modal-box__body {
  --pf-c-modal-box__body--PaddingTop: 2rem;
}

/* Table overrides */
.pf-c-table {
  --pf-c-table-caption--PaddingTop: 0;
}
.vertical-align-middle.pf-c-table tbody > tr > * {
  vertical-align: middle;
}

/* Patient history */
.patient-history-record {
  margin-bottom: 20px;
}

/* Draft order form */
.draft-order-form.pf-c-form {
  --pf-c-form--m-horizontal__group-label--md--GridColumnWidth: 200px;
}
.draft-order-form .pf-c-form__field-group-header-title-text {
  font-weight: bold;
}

/* Patient conversations */
.conversation-message-details-customer {
  position: absolute;
  top: 50%;
  right: -150px;
  color: #ccc;
  font-size: 12px;
  width: 130px;
  margin-top: -10px;
}
.conversation-message-details-employee {
  position: absolute;
  top: 50%;
  left: -250px;
  color: #ccc;
  font-size: 12px;
  width: 230px;
  margin-top: -10px;
  text-align: right;
}
.conversation-message-details-employee span {
  display: inline-block;
  padding-left: 10px;
}

/* Internal conversations */
.internal-conversation-modal .pf-c-check__label {
  font-size: 14px;
}
.internal-conversation-message {
  position: relative;
}
.internal-conversation-message-details {
  position: absolute;
  display: block;
  top: 0;
  left: 75%;
  color: #333;
  font-size: 13px;
}
.internal-conversation-message-details span {
  display: block;
  padding: 0 0 0 7px;
}
.internal-conversation-message-details span a {
  color: #333;
}
.internal-conversation-message-details span a:hover {
  color: red;
}

/* Customer image gallery */
.customer-image-gallery-item {
  cursor: pointer;
}
.customer-image-gallery-item-img-wrap {
  height: 215px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('https://placehold.co/250x250?text=Loading...');
}
.customer-image-gallery-item-img-wrap-error {
  background-image: url('https://placehold.co/250x250?text=Error');
}
.customer-image-gallery-item-img-wrap-error img {
  display: none;
}
.customer-image-gallery-item-img {
  width: 100%;
  display: block;
}
.customer-image-gallery-item-desc {
  background-color: #eee;
  font-size: 12px;
  padding: 10px;
}

/* Customer dataaset edit + products */
.iframe-container {
  position: relative;
}
.iframe-container iframe {
  width: 100%;
  height: calc(100vh - 250px);
  border: 0;
}
.iframe-container .spinner {
  position: absolute;
  top: 0;
  left: 0;
}

.customer-dataset-form {
  --pf-c-form--m-horizontal__group-label--md--GridColumnWidth: 280px;
}
#customer-dataset-details-modal {
  min-height: 600px;
}

/* Customer dashboard */
.patient-details-dashboard {
  --pf-c-content--FontSize: 14px;
  --pf-c-content--dl--ColumnGap: 20px;
  --pf-c-content--dl--RowGap: 5px;

  margin-bottom: 20px;
  line-height: 26px;
}

/* Line items evaluate modal */
.line-items-evaluate-modal .pf-c-check__label {
  font-size: 14px;
}

/* Application launcher (top header) */
.pf-c-app-launcher__menu-item-icon {
  --pf-c-app-launcher__menu-item-icon--Width: 60px;
  --pf-c-app-launcher__menu-item-icon--Height: 30px;
  --pf-c-app-launcher__menu-item-icon--MarginRight: 15px;
}
