.footer {
  text-align: right;
  height: 33px;
}

.color {
  outline-style: solid;
  outline-color: coral;
}

.cardBody {
  display: flex;
  justify-content: left;
}
